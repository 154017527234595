import Gizmo from "../components/list/gizmo";
import "./Home.scss";
import {SetStateAction, useEffect, useState} from "react";
import Token from "../components/list/Token";
import axios from "../../hooks/Axios";
import {OrbitProgress} from "react-loading-indicators";
import Loading from "../components/Loading";
import CustomTextBox from "../components/textbox/CustomTextBox";
import gsap from "gsap";
import {useGSAP} from "@gsap/react";

import Prism from 'prismjs';
import 'prismjs/themes/prism.css'; // Import a Prism theme

const sampleTokens = [
    new Token("Hello", 0.81),
    new Token("World", 0.61),
    new Token("how's", 0.41),
    new Token("it", 0.21),
    new Token("going'", 0.19),
]

enum gizmoDisplayMode {
    listFormat = "list-format ",
    fanFormat = "fan-format "
}

class ModelRequest {
    prompt: string;
    n_predict: number;
    temperature?: number;
    top_k?: number;
    top_p?: number;
    cache_prompt?: boolean;
    n_probs?: number;
    stop?: string[];

    constructor(question: string) {
        this.prompt = question;
        this.n_predict = 3;
        this.n_probs = 50;
    }

    toJSONString() {
        return JSON.stringify(this);
    }
}

interface ModelResponse {
    content: string
}

function Home() {
    const [count, setCount] = useState(0);
    const [[xPos, yPos], setPos] = useState([100, 100])
    const [gizmoTokens, setGizmo] = useState<Token[]>([])

    const [question, setQuestion] = useState('')

    const [response, setResponse] = useState<string>('');
    const [responseLoading, setResponseLoading] = useState<boolean>(false);

    // Function to call backend API (POST request)
    function sendChatCompletion() {
        setResponseLoading(true);

        let request = new ModelRequest(question);

        // TODO: Possible way to ask this via an interface.
        // request.temperature = 0.2;
        // request.top_k = 40;
        // request.top_p = 0.9;
        // request.n_predict = 5;
        // request.cache_prompt = false;
        // request.n_probs = 15;
        // request.stop = ["\n### Human:"];

        axios.post<ModelResponse>("/completion", request.toJSONString())
            .then((apiResponse) => {
                const response: ModelResponse = apiResponse.data;
                setResponse(response.content);
                setResponseLoading(false);
            })
            .catch((error) => {
                console.log("There was a problem with error: ", error.message);
            });
    }

    // Dummy function to demostrate gizmo can be placed anywhere
    function randomizeLocation() {
        const xCoord = Math.floor(Math.random() * 800)
        const yCoord = Math.floor(Math.random() * 600)
        setPos([xCoord, yCoord])
    }

    function loadGizmo(tokens: Token[]) {
        setPos([100, 100]) // This should be set at highlighted word
        setGizmo(tokens)
    }

    function hideGizmo(chosenWord: string) {
        console.log(chosenWord != null ? chosenWord : "No change");
        setGizmo([])
    }

    // Animate buttons for tokens
    useGSAP(() => {
        gsap.from(".choice-button", {
            left: 0,
            top: 0,
            duration: 0.1,
            ease: "power1.out"
        });
    }, [gizmoTokens]);

    useEffect(() => {
        console.log("IS LOADING: " + responseLoading);
    }, [responseLoading]);


    const [code, setCode] = useState('');
    useEffect(() => {
        Prism.highlightAll();
    }, [code]);

    const handleCodeChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setCode(e.target.value);
    };

    return (
        <div className="Home">
            <h1>Hello, world! Count {count}</h1>
            <button onClick={() => setCount(count + 1)}>Click Me!</button>
            <button onClick={() => loadGizmo(sampleTokens)}>Spawn Gizmo</button>
            {gizmoTokens.length > 0 ? <button onClick={randomizeLocation}>Move Gizmo</button> : null}
            {gizmoTokens.length > 0 ?
                <Gizmo xPos={xPos} yPos={yPos} tokens={gizmoTokens} format={gizmoDisplayMode.fanFormat}
                       hideFunc={hideGizmo}/> : null}
            <div>
                <input
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Ask a question"
                />
                <button onClick={sendChatCompletion}>Send to Backend</button>
            </div>

            <div className={"test-highlight"}>
                <p>test</p>
                <textarea value={code} onChange={handleCodeChange}/>
                <pre>
                    <code className="language-javascript">
                      {code}
                    </code>
                </pre>
            </div>

            {/* TODO: Eventually get REAL probabilities. */}
            <CustomTextBox word_probabilities={[1.0, 0.75, 0.6, 0.45, 0.3, 0.0]}/>

            {
                (responseLoading) ?
                    <Loading/> :
                    <div className={"model-response"}>{response}</div>
            }

        </div>
    )
}

export default Home;

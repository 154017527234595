import "./PageLayout.scss";
import {useEffect, useState} from "react";
import {Outlet} from "react-router";
import {Link} from "react-router-dom";

enum CurrentPage {
    HOME = "/",
    TESTPAGE1 = "/testpage1",
    TESTPAGE2 = "/testpage2",
}

const pages = [CurrentPage.HOME, CurrentPage.TESTPAGE1, CurrentPage.TESTPAGE2];

const PageLayout = () => {
    const [pageIndex, setPageIndex] = useState(0);
    const [previousIndex, setPreviousIndex] = useState(pages.length - 1);
    const [nextIndex, setNextIndex] = useState(1);

    useEffect(() => {
        const tempPrevIndex = pageIndex - 1;
        const tempNextIndex = pageIndex + 1;

        const previousIndex = (tempPrevIndex % pages.length + pages.length) % pages.length;
        const nextIndex = (tempNextIndex % pages.length + pages.length) % pages.length;

        setPreviousIndex(previousIndex);
        setNextIndex(nextIndex);
    }, [pageIndex]);

    return (
        <>
            <nav className={"Navbar"}>
                <ul>
                    <li><Link onClick={() => setPageIndex(previousIndex)} to={pages[previousIndex]}>{pages[previousIndex]}</Link></li>
                    <li><Link onClick={() => setPageIndex(nextIndex)} to={pages[nextIndex]}>{pages[nextIndex]}</Link></li>
                </ul>
            </nav>

            <Outlet/>

            <footer className={"footer"}>
                <p>ECS 289H | James, Garrick, William</p>
            </footer>
        </>
    );
}

export default PageLayout;

import "./textButton.css";

function setColor(probability: number){
    if (probability > 0.8){
        return "high-prob ";
    } else if (probability > 0.6){
        return "mid-high-prob ";
    } else if (probability > 0.4){
        return "mid-prob ";
    } else if (probability > 0.2){
        return "mid-low-prob ";
    } else if (probability >= 0){
        return "low-prob ";
    } else {
        return "no-prob ";
    }
}

interface TextButtonProps {
    rank: string;
    word: string;
    probability: number;
    hideFunc: Function;
}

const TextButton = (props: TextButtonProps) => {
    return (
        <div className={"choice-button choice-rank-" + props.rank} onClick={() => props.hideFunc(props.word)}>
                <span className={"choice-text " + setColor(props.probability) + "choice-num "}>{props.rank}</span>
                <span className={"choice-text " + setColor(props.probability)}>{props.word ? props.word : "(no change)"}</span>

        </div>
    )
}

export default TextButton;

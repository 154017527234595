import { div } from "framer-motion/client";
import "./gizmo.css";
import TextButton from "./textButton";
import Token from "./Token";

interface GizmoProps {
    xPos: number;
    yPos: number;
    tokens: Token[];
    hideFunc: Function;
    format: string;
}

const Gizmo = (props: GizmoProps) => {
    const position = {
        top: props.yPos,
        left: props.xPos
    }

    return (
        <div className={"gizmo " + props.format} style={position}>
            {props.tokens.map((token: Token, index: number) => (
                <div key={index}>
                    <TextButton rank={(index+1).toString()} word={token.word} probability={token.probability} hideFunc={props.hideFunc}/>
                </div>
            ))}
            <div key={-1}>
                <TextButton rank={"×"} word={""} probability={-1} hideFunc={props.hideFunc}/>
            </div>
        </div>
    )
}

export default Gizmo;

import React from 'react';
import './App.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./routes/home/Home";
import PageLayout from "./routes/components/PageLayout/PageLayout";
import TestPage2 from "./routes/TestPage2/TestPage2";
import TestPage1 from "./routes/TestPage1/TestPage1";


function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path = "/" element={<PageLayout/>}>
                <Route index element={<Home />} />

                <Route path={"testpage1"} element={<TestPage1/>} />
                <Route path={"testpage2"} element={<TestPage2/>} />
            </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;

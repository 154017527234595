import "./TestPage1.scss";

const TestPage1 = () => {
    return (
        <div className="TestPage1">
            <h1>Some design here...</h1>
        </div>
    );
}

export default TestPage1;

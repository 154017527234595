import React, {useEffect} from "react";
import "./CustomTextBox.scss";
import axios from "../../../hooks/Axios";

// class Word {
//     value: string;
//     probability: number;
//
//     constructor(value: string, probability: number) {
//         this.value = value;
//         this.probability = probability;
//     }
// }
//

class CompletionRequest {
    prompt: string;
    n_predict: number;
    temperature?: number;
    top_k?: number;
    top_p?: number;
    cache_prompt?: boolean;
    n_probs?: number;
    stop?: string[];

    constructor(prompt: string) {
        this.prompt = prompt;
        this.n_predict = 2;
        this.n_probs = 50;

        // Caching the prompt will dramatically reduse computational
        // load. Instead of rerunning everything the user has previously
        // typed, the previously computed state (KV cache) will be used.
        this.cache_prompt = true;
    }

    toJSONString() {
        return JSON.stringify(this);
    }

    async fetchCompletion(): Promise<CompletionResponse> {
        try {
            const response = await axios.post<CompletionResponse>('/completion', this.toJSONString());
            return response.data;
        } catch (error) {
            if (error instanceof Error) {
                console.error("CompletionResponse: There was a problem with error:", error.message);
            } else {
                console.error("CompletionResponse: An unknown error occurred:", error);
            }
            throw error;
        }
    }
}

interface Probability {
    tok_str: string;
    prob: number;
}

interface ContentWithProbabilities {
    content: string
    probs : Probability[]
}

interface CompletionResponse {
    content: string
    completion_probabilities: ContentWithProbabilities[]
}

class TokenizationRequest {
    content: string;
    add_special?: boolean;
    with_pieces?: boolean;

    constructor(content: string) {
        this.content = content;
        this.with_pieces = true;
    }

    toJSONString() {
        return JSON.stringify(this);
    }

    async fetchTokenization(): Promise<TokenizationResponse> {
        try {
            const response = await axios.post<TokenizationResponse>('/tokenize', this.toJSONString());
            return response.data;
        } catch (error) {
            if (error instanceof Error) {
                console.error("TokenizationResponse: There was a problem with error:", error.message);
            } else {
                console.error("TokenizationResponse: An unknown error occurred:", error);
            }
            throw error;
        }
    }
}

interface Token {
    id: number;
    piece: string;
    prob?: number;
    all_probs?: Probability[];
}

interface TokenizationResponse {
    tokens: Token[];
}

// An in-order array of word probabilities.
interface CustomTextBoxProps {
    word_probabilities: number[];
}

class WordProbabilityValue {
    probability: number;
    color: string;

    constructor(probability: number, color: string) {
        this.probability = probability;
        this.color = color;
    }
}

class WordProbabilityColor {
    static readonly LOW  = new WordProbabilityValue(0.01, "#ff002f");
    static readonly MEDIUM = new WordProbabilityValue(0.05, "#faa12d");
    static readonly HIGH  = new WordProbabilityValue(0.7, "#FFC0CB00");

    // private to disallow creating other instances of this type
    // https://stackoverflow.com/questions/41179474/use-object-literal-as-typescript-enum-values
    private constructor(private readonly key: string, public readonly value: any) { }
}

const getProbabilityColor = (probability : number) : string => {
    if (probability > WordProbabilityColor.HIGH.probability || probability === undefined) {
        return WordProbabilityColor.HIGH.color;
    }

    if (probability > WordProbabilityColor.MEDIUM.probability) {
        return WordProbabilityColor.MEDIUM.color;
    }

    return WordProbabilityColor.LOW.color;
}

function tokensToString(tokens: Token[]): string {
    return tokens.map(token => token.piece).join("");
}

async function generateProbabilities(
    previousTokens: Token[],
    newTokens: Token[]
): Promise<Token[]> {
    // Iterate through both previousTokens and tokens.
    // If the tokens differ, perform generation on all
    // the tokens after the last token that is the same.
    let tokens = [];
    
    let differingIndex = 0;

    // Find the first token index where they differ
    while (
        differingIndex < previousTokens.length &&
        differingIndex < newTokens.length &&
        previousTokens[differingIndex].id === newTokens[differingIndex].id
    ) {
        // Push previousTokens since they have valid probabilities.
        tokens.push(previousTokens[differingIndex]);
        differingIndex++;
    }

    // If no differences found and tokens are identical to previousTokens, return an empty array
    if (newTokens.length === tokens.length) {
        return tokens;
    }

    // Iterate over the rest of newTokens.
    for (let i = differingIndex; i < newTokens.length; i++) {
        // Replace this with actual probability generation logic
        let prompt = tokensToString(newTokens.slice(0, i))
        //console.log(`Generating token probability for: '${newTokens[i].piece}' '${prompt}'`);
        if (prompt === "") {
            tokens.push(newTokens[i]);
            continue;
        }
        let request = new CompletionRequest(prompt);
        console.log("Request: ", request);
        try {
            const response = await request.fetchCompletion();
            newTokens[i].prob = 0.0;
            console.log(response)
            newTokens[i].all_probs = response.completion_probabilities[0].probs;
            for (let k = 0; k < response.completion_probabilities[0].probs.length; k++) {
                if (response.completion_probabilities[0].probs[k].tok_str === newTokens[i].piece) {
                    newTokens[i].prob = response.completion_probabilities[0].probs[k].prob;
                    break; 
                }
            }
        } catch (error) {
            console.log("Error: ", error);
            throw error
        }
        tokens.push(newTokens[i]);
    }

    return tokens;

}

const CustomTextBox = (props : CustomTextBoxProps) => {
    const [rawContent, setRawContent] = React.useState('');
    const [modifiedContent, setModifiedContent] = React.useState<JSX.Element[]>([]);
    const [previousText, setPreviousText] = React.useState<string>('');
    const [typingTimeout, setTypingTimeout] = React.useState<NodeJS.Timeout | null>(null);
    const [previousTokenization, setPreviousTokenization] = React.useState<Token[]>([]);

    useEffect(() => {
        const spans = previousTokenization.map((token, index) => {
            let prob = 1.0;
            if (token.prob !== undefined) {
                prob = token.prob;
            }
            const color = getProbabilityColor(prob);
            return (
                <span key={index}>
                    <span style={{backgroundColor: color}} key={index}>{token.piece}</span>{" "}
                </span>
            )
        });
        //const splitContent : string[] = rawContent.split(" ");

        //const spans = splitContent.map((word, index) => {
        //    const probability = props.word_probabilities[index];
        //    const color = getProbabilityColor(probability);

        //    return (
        //        <span key={index}>
        //            <span style={{backgroundColor: color}} key={index}>{word}</span>{" "}
        //        </span>
        //    )
        //});

        setModifiedContent(spans);
    }, [rawContent, props.word_probabilities, previousTokenization]);

    const findFirstChange = (oldText: string, newText: string): number => {
        let i = 0;
        while (i < oldText.length && i < newText.length && oldText[i] === newText[i]) {
            i++;
        }
        return i;
    };

    // const [rowContent, setRowContent] = React.useState('');
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        
        const newTimeout = setTimeout(() => {
            const index = findFirstChange(previousText, event.target.value);
            // Call to the backend. We only update the raw content when the timer
            // triggers and the backend returns a response.
            setRawContent(event.target.value);

            let request = new TokenizationRequest(event.target.value);
            request.fetchTokenization()
                .then((response) => {
                    generateProbabilities(previousTokenization, response.tokens).then((newTokens) => {
                        setPreviousTokenization(newTokens)
                        console.log(newTokens)
                    }).catch((error) => {
                        console.log("Error: ", error.message);
                    })
                })
                .catch((error) => {
                    console.log("There was a problem with error: ", error.message);
                });

        }, 200);
        
        setTypingTimeout(newTimeout);
    }

    return (
        <div className="CustomTextBox">
            <div className="ModifiedContent">{modifiedContent}</div>
            <textarea cols={50} rows={10} onChange={handleChange} ></textarea>
        </div>
    );
}

export default CustomTextBox;

import a from "axios";

const axios = a.create({
    // baseURL: 'http://localhost:7171',  // Will
    baseURL: 'http://127.0.0.1:7171',
});

// TODO: Debugging purposes. Console logs every request.
axios.interceptors.request.use(request => {
    //console.log('Request:', JSON.stringify(request)); // Debug
    return request;
});

// TODO: Debugging purposes. Console logs every response.
axios.interceptors.response.use(response => {
    //console.log('Response:', JSON.stringify(response)); // Debug
    return response;
});

export default axios;

import "./TestPage2.scss";

const TestPage2 = () => {
    return (
        <div className="TestPage2">
            <h1>Some OTHER design here...</h1>
        </div>
    );
}

export default TestPage2;
